import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import { PageContext } from "../page-context";

export const TabWrapper = ({ children }) => {
  const nav = ReactRouterDom.useNavigate();
  const page = React.useContext(PageContext);
  const { lang } = page;
  const url = ["/foreign-form-key", "/national-form-key"];
  return (
    <div>
      {children}
      <select
        style={{
          display: url.includes(window.location.pathname) ? "block" : "none",
          position: "absolute",
          top: 8,
          right: 8,
        }}
        onChange={(e) => {
          const kind = e.target.value;
          if (kind === "NATIONAL") {
            nav("/national-form-key");
            return;
          }
          if (kind === "FOREIGN") {
            nav("/foreign-form-key");
            return;
          }
          throw new Error();
        }}
        value={lang}
      >
        <option value="NATIONAL">한국인(한국어)</option>
        <option value="FOREIGN">Foreigner(English)</option>
      </select>
    </div>
    // <Mui.Box>
    //   <Mui.Tabs
    //     value={lang}
    //     centered
    //     style={{
    //       display: url.includes(window.location.pathname) ? "block" : "none",
    //     }}
    //   >
    //     <Mui.Tab
    //       value='NATIONAL'
    //       label='한국인(한국어)'
    //       onClick={() => nav("/national-form-key")}
    //     />
    //     <Mui.Tab
    //       value='FOREIGN'
    //       label='Foreigner(English)'
    //       onClick={() => nav("/foreign-form-key")}
    //     />
    //   </Mui.Tabs>
    //   <div style={{ height: 24 }} />
    //   {children}
    // </Mui.Box>
  );
};
