import React from "react";
import * as ReactRouterDom from "react-router-dom";

export const RedirectToNationalFormKey = () => {
  const nav = ReactRouterDom.useNavigate();
  React.useEffect(
    () => nav("/national-form-key", undefined, { replace: true }),
    []
  );
  return null;
};
