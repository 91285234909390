import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import { useSysforuForeignForm } from "../sysforu-form";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import { useAttachErrorHandler } from "../app-error-handler";
import styles from "./national-form-key.module.css";
import { filter } from "lodash";

export const ForeignFormKey = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuForeignForm();
  const attachErrorHandler = useAttachErrorHandler();
  const apolloClient = React.useContext(ApolloClientContext);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onNext = attachErrorHandler(async () => {
    // 입력 검사
    if (formState.value.name.length === 0) {
      alert("Please enter your name.");
      return;
    }
    if (formState.value.email.length === 0) {
      alert("Please enter your email.");
      return;
    }

    if (!validateEmail(formState.value.email)) {
      alert("The email format is not valid.");
      return;
    }

    // 키가 이미 등록되었는지 확인하고, 그렇다면 4 페이지로
    {
      const result = await apolloClient.query({
        query: GraphqlTypes.FormForeignReadByKeyFormDocument,
        variables: {
          keyForm: { name: formState.value.name, email: formState.value.email },
        },
      });
      const exists = result.data.formForeignReadByKeyForm != null;
      if (exists) {
        alert("You're already registered.");

        nav(
          `/foreign-form-result?key=${result.data.formForeignReadByKeyForm.id}`
        );
        return;
      }
    }

    // 아니면 그냥 다음으로
    nav(`/foreign-form-agreements?${formState.getQueryString()}`);
  });

  const filterEmail = (e) => {
    const regExp = /[^0-9a-zA-Z@.]/g;
    let ele = e.target.value;
    if (regExp.test(ele)) {
      ele = ele.replace(regExp, "");
    }
    console.log(ele);
    formState.set({ ...formState.value, email: ele });
  };

  return (
    <div className={styles.cont}>
      <img style={{ width: "100%" }} src={require("../banner.png")} />
      <Mui.Typography
        variant='h6'
        style={{
          width: "100%",
          textAlign: "center",
          color: "white",
          backgroundColor: "#F0569A",
        }}
      >
        On-site Registration
      </Mui.Typography>
      <div className={styles.innerCont}>
        <Mui.TextField
          label='Name'
          placeholder='Firstname and Lastname'
          variant='outlined'
          value={formState.value.name}
          onChange={(e) =>
            formState.set({ ...formState.value, name: e.target.value })
          }
        />
        <Mui.TextField
          label='Email'
          placeholder='Enter your email'
          variant='outlined'
          value={formState.value.email}
          onChange={(e) => filterEmail(e)}
        />
        <Mui.Button variant='contained' onClick={onNext}>
          Next
        </Mui.Button>
      </div>
    </div>
  );
};
