export const usePhoneToString = () => {
  // const countries = React.useContext(Contexts.CountriesContext);

  // const conutryCodes = countries.map((country) => country.codes).flat();
  // const countryCodeSet = new Set(countryCodes);

  return (phone, defaultCode = "+82") => {
    if (phone.startsWith("+")) {
      return phone;
    }
    return `${phone}`;
    // if (phone.startsWith('+')) {
    //   const matchingCountryCode = countryCodes.find(code => phone.startsWith(code))

    // }
  };
};

export const urlJsonSerialize = (item) => encodeURI(JSON.stringify(item));
export const urlJsonParse = (itemStr) => JSON.parse(decodeURI(itemStr));

export const run = (fn, handleError) => {
  try {
    const result = fn();
    return result;
  } catch (e) {
    return handleError(e);
  }
};

//국제전화번호 형식 체크
export function checkInternationalPhone(phone) {
  //var pattern = /^\+\d{1,3}\s(\d{1,}\s)?(\d{2,4})(-\d{2,4}){1\0,}(-\d{2,4})$/;
  var pattern = /^[+\d{1,}|\d{1,}][-\s|\d|(|)]{0,}[0-9]{4,}$/;
  var result = pattern.test(phone);
  return result;
}

//전화번호 최소 길이 체크
function checkMinimumPhoneLength(phone, length) {
  phone = phone.replace(/[^0-9]/g, "");
  var result = phone.length >= length ? true : false;
  return result;
}

// 번호에 하이픈 넣기
export function insertHypen(n) {
  n = n.toString().replace(/-/g, "");
  var pattern = /^(?:010)|^(?:011)|^(?:016)|^(?:017)|^(?:018)|^(?:019)/;
  if (pattern.test(n)) {
    if (n.length == 3) {
      n = n.replace(/(\d{3})/, "$1");
    }
    if (n.length == 4) {
      n = n.replace(/(\d{3})(\d{1})/, "$1-$2");
    }
    if (n.length == 5) {
      n = n.replace(/(\d{3})(\d{2})/, "$1-$2");
    }
    if (n.length == 6) {
      n = n.replace(/(\d{3})(\d{3})/, "$1-$2");
    }
    if (n.length == 7) {
      n = n.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    }
    if (n.length == 8) {
      n = n.replace(/(\d{3})(\d{3})(\d{2})/, "$1-$2-$3");
    }
    if (n.length == 9) {
      n = n.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
    }
    if (n.length == 10) {
      n = n.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    if (n.length == 11) {
      n = n.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  }
  return n;
}

// 전화번호에 하이픈 넣기
export function insertHypenToTelephone(n) {
  n = n.toString().replace(/-/g, "");
  var pattern =
    /^(?:02)|^(?:031)|^(?:032)|^(?:033)|^(?:041)|^(?:042)|^(?:043)|^(?:044)|^(?:051)|^(?:052)|^(?:053)|^(?:054)|^(?:055)|^(?:061)|^(?:062)|^(?:063)|^(?:064)|^(?:070)|^(?:080)|^(?:0303)|^(?:0502)|^(?:0505)/;
  if (pattern.test(n)) {
    var localN = pattern.exec(n);
    let withoutLocalN = n.replace(pattern, "");
    if (withoutLocalN.length == 4) {
      withoutLocalN = withoutLocalN.replace(/(\d{3})(\d{1})/, "$1-$2");
    }
    if (withoutLocalN.length == 5) {
      withoutLocalN = withoutLocalN.replace(/(\d{3})(\d{2})/, "$1-$2");
    }
    if (withoutLocalN.length == 6) {
      withoutLocalN = withoutLocalN.replace(/(\d{3})(\d{3})/, "$1-$2");
    }
    if (withoutLocalN.length == 7) {
      withoutLocalN = withoutLocalN.replace(/(\d{3})(\d{4})/, "$1-$2");
    }
    if (withoutLocalN.length == 8) {
      withoutLocalN = withoutLocalN.replace(/(\d{4})(\d{4})/, "$1-$2");
    }
    if (withoutLocalN != "") {
      n =
        withoutLocalN.indexOf("-") == -1 && withoutLocalN.length >= 9
          ? localN + withoutLocalN
          : localN + "-" + withoutLocalN;
    } else {
      n = localN;
    }
  } else {
    let withoutLocalN = n;
    if (n.length == 8) {
      n = n.replace(/(\d{4})(\d{4})/, "$1-$2");
    }
  }
  return n;
}

//숫자만 입력받기
export function onlyNumber(e) {
  // Allow: backspace, delete, tab, escape, enter and .
  //if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
  // Allow: backspace, delete, tab, escape, enter
  if (
    [46, 8, 9, 27, 13, 110].includes.keyCode ||
    // Allow: Ctrl/cmd+A
    (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: Ctrl/cmd+C
    (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: Ctrl/cmd+X
    (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    // let it happen, don't do anything
    throw new Error();
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
}
//한글 입력 방지
export function no_korean(a) {
  return a.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, "");
}
//이메일 형식 체크
export function email_chk(email, which) {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/;
  if (which == "front") {
    emailPattern = /^[a-zA-Z0-9._-]+$/;
  } else if (which == "back") {
    emailPattern = /^[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/;
  } else {
    emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/;
  }

  if (!emailPattern.test(email)) {
    throw new Error();
  }
}

export const domEventTargetStr = (f) => (e) => f(e.target.value);

export const domValue = (e) => e.target.value;

export const catchError = (f) => {
  try {
    return { value: f() };
  } catch (e) {
    return { error: e };
  }
};
