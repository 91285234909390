import React from "react";
import { PageContext } from "../page-context";

export const useTranslateByPageLang = () => {
  const page = React.useContext(PageContext);
  const { lang } = page;

  return ({ ko, en }) => {
    if (lang === "NATIONAL") {
      return ko;
    }
    if (lang === "FOREIGN") {
      return en;
    }
  };
};
