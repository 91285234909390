import React from "react";
import * as Mui from "@mui/material";
import QrCode from "react-qr-code";
import { useTranslateByPageLang } from "../hook/translate-by-page-lang";

export const QrContainer = ({ qrSource, name, code, isInvited }) => {
  const tr = useTranslateByPageLang();
  const backgroundColor = isInvited ? "#8749c5" : "#f8006f";

  const heading = isInvited
    ? tr({ ko: "등록완료 초대권", en: "REGISTERATION COMPLETED" })
    : tr({
        ko: "현장등록(미결제)",
        en: "On-site Registration\n(Payment required)",
      });

  const guide = isInvited
    ? tr({
        ko: (
          <ol>
            <li>
              셀프 체크인 (출입증 무인 발급) 키오스크에 QR코드를 스캔하면
              출입증이 발급됩니다.
            </li>
            <li>출력된 출입증 용지와 목걸이를 결합 후 입장하세요.</li>
          </ol>
        ),
        en: (
          <ol>
            <li>
              (Self Check-in) Scan this QR code at the kiosk to get your pass.
            </li>
            <li>Combine your printed pass and lanyard to enter.</li>
          </ol>
        ),
      })
    : tr({
        ko: (
          <ol>
            <li>
              셀프 체크인(출입증 무인 발급) 키오스크에 QR코드를 스캔하세요.
            </li>
            <li>현장결제를 완료하시면 출입증이 발급됩니다.</li>
            <li>출력된 출입증 용지와 목걸이를 결합 후 입장하세요.</li>
          </ol>
        ),
        en: (
          <ol>
            <li>
              (Self Check-in) Scan this QR code on the kiosk at the registration
              desk.
            </li>
            <li>
              You will receive your pass upon completion of on-site payment.
            </li>
            <li>Combine your printed pass and lanyard to enter.</li>
          </ol>
        ),
      });

  return (
    <Mui.Box
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        border: "1px solid gray",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
      }}
    >
      <Mui.Box
        sx={{
          display: "flex",
          backgroundColor,
          alignItems: "center",
          padding: 1,
        }}
      >
        <Mui.Typography
          variant="h6"
          sx={{
            color: "white",
            textAlign: "center",
            flex: 1,
            whiteSpace: "pre",
          }}
        >
          {heading}
        </Mui.Typography>
      </Mui.Box>
      <Mui.Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <QrCode value={qrSource} size={100} style={{ marginTop: "10px" }} />
        </div>
        {/* remove by client request */}
        {/* <Mui.Typography
          variant="body1"
          sx={{
            fontFamily: "monospace",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {code.slice(0, 5)}-{code.slice(5, 11)}
        </Mui.Typography> */}
        <Mui.Typography variant="body1" sx={{ textAlign: "center" }}>
          {name}
        </Mui.Typography>
      </Mui.Box>
      <Mui.Box sx={{ borderTop: "1px solid gray", padding: 1 }}>
        <Mui.Typography variant="caption">{guide}</Mui.Typography>
      </Mui.Box>
      {/* <Mui.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor,
          alignItems: "center",
          padding: 1,
          color: "white",
        }}
      >
        <Mui.Typography
          variant="caption"
          style={{ color: "white", border: "none" }}
        >
          SIMTOS 2024 / M.A.D.E. 2024
        </Mui.Typography>
        <Mui.Typography
          variant="caption"
          style={{ color: "white", border: "none" }}
        >
          SIMTOS 2024
        </Mui.Typography>
      </Mui.Box> */}
    </Mui.Box>
  );
};
