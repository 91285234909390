export const privacyPolicyEn = `Agree personal information
According to the Personal Information Protection Act, we inform you of the purpose of collecting and using personal information, items of personal information, retention and use period of personal information, rights to reject consent, and disadvantages. Please read carefully and agree to our following statement.
Article 1 Purpose of Collection and Use of Personal Information
A. The SIMTOS Secretariat collects personal information for the following purposes.

Announcement to exhibition notices and guide to the next exhibition
Sending SIMTOS Newsletter
Guides of other services and information
B. SIMTOS Secretariat shall not use the collected personal information for any purpose other than the above except in the case of relevant statutes or prior consent of the user, and shall obtain prior consent from the user if it intends to use it beyond the scope of use.

Article 2 Personal Information Collection Items
SIMTOS Secretariat collects the following items to provide services:

Collection items: company name(English), name, department, position, mobile phone number, company phone number, e-mail address, company address

Article 3 Retention period of personal information
A. Personal information provided by users will be retained and used by the SIMTOS Secretariat for five years after applying for participation in the exhibition.

B. In any of the following cases, the user's personal information shall be deleted or destroyed and shall not be reproduced or used in any case

A user requests deletion due to resignation, transfer, etc.
When user no longer wants to use the information or loses his/her qualification;
When the purpose of collecting and using information has been achieved.
Article 4 Entrustment of Personal Information
A. Entrustment of personal information

Entrustment company: SIMTOS designated official contractor (operating agency, carry in/ out, booth design, electricity, compressed air, telephone/Internet, visitor data management system, survey, furniture rental, directory, etc.)

B. When entrusting the user's personal information for smooth business processing, the SIMTOS Secretariat will notify the name of the entrusted processing company and the scope of entrustment in advance on the user email and SIMTOS website.

Article 5 Right to refuse consent to collect and use personal information Users has the right to refuse consent to collect and use personal information. If you refuse to accept the consent to collect and use the personal information when applying for SIMTOS 2024, you shall not be eligible to participate in SIMTOS 2024.`;

export const privacyPolicyKrUrl = "https://simtos.org/policy/privacy.asp";

export const apiAddr = "https://api.form1.bizforu.among.software";

export const apiAddrGraphql = `${apiAddr}/graphql`;

export const defaultInvitationCode = "1000011";
