import * as R from "ramda";

const graphqlErrorCodeLens = R.lensPath([
  "graphQLErrors",
  0,
  "extensions",
  "code",
]);

export const handleError = (error) => {
  const graphqlErrorCode = R.view(graphqlErrorCodeLens, error);
  if (graphqlErrorCode === "errors/emailDuplicated") {
    alert("이미 등록된 이메일입니다.");
    return;
  }

  if (graphqlErrorCode === "errors/phoneDuplicated") {
    alert("이미 등록된 휴대전화 번호입니다.");
    return;
  }

  if (graphqlErrorCode === "errors/invitationNotAvailable") {
    alert("사용할 수 있는 초대권 번호가 아닙니다.");
    return;
  }

  console.error(error);
  console.error(JSON.stringify(error));
  alert(`오류가 발생하였습니다.\n${JSON.stringify(error)}`);
};
