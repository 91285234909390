import React from "react";
import * as Mui from "@mui/material";
import * as ReactRouterDom from "react-router-dom";
import * as ApolloClient from "@apollo/client";
import * as AppApolloClient from "./apollo-client";
import * as AppData from "./app-data";
import * as AppRouter from "./router";
import * as Countries from "./countries";
import * as InterestedProducts from "./interested-products";
import * as RelatedIndustries from "./related-industries";
import * as PurposeOfVisits from "./purpose-of-visits";

const theme = Mui.createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "h6" },
          style: {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
        {
          props: { variant: "caption" },
          style: {
            fontSize: "13px",
            fontWeight: 400,
            color: "#999",
            borderBottom: "1px solid #ddd",
            whiteSpace: "pre-wrap",
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
      style: {
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    MuiFormControlLabel: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  },
  typography: {
    fontFamily: "'Noto Sans KR', sans-serif",
  },
  palette: {
    primary: {
      main: "#f0569a",
    },
    grey: {
      main: "#808080",
      contrastText: "#fff",
    },
    black: {
      main: "#000000",
    },
  },
});
const InnerApp = () => {
  const router = AppRouter.createRouter();

  const countriesQuery = Countries.query();
  const interestedProductsQuery = InterestedProducts.query();
  const relatedIndustriesQuery = RelatedIndustries.query();
  const purposeOfVisitsQuery = PurposeOfVisits.query();

  if (
    [
      countriesQuery,
      interestedProductsQuery,
      relatedIndustriesQuery,
      purposeOfVisitsQuery,
    ].some((a) => a.data == null)
  ) {
    return null;
  }

  return (
    <Mui.ThemeProvider theme={theme}>
      <Countries.CountriesContext.Provider
        value={countriesQuery.data.countryReadAll}
      >
        <InterestedProducts.InterestedProductsContext.Provider
          value={interestedProductsQuery.data.interestedProductReadAll}
        >
          <RelatedIndustries.RelatedIndustriesContext.Provider
            value={relatedIndustriesQuery.data.relatedIndustryReadAll}
          >
            <PurposeOfVisits.PurposeOfVisitsContext.Provider
              value={purposeOfVisitsQuery.data.purposeOfVisitReadAll}
            >
              <ReactRouterDom.RouterProvider router={router} />
            </PurposeOfVisits.PurposeOfVisitsContext.Provider>
          </RelatedIndustries.RelatedIndustriesContext.Provider>
        </InterestedProducts.InterestedProductsContext.Provider>
      </Countries.CountriesContext.Provider>
    </Mui.ThemeProvider>
  );
};

export const App = () => {
  const apolloClient = AppApolloClient.createApolloClient({
    apiAddrGraphql: AppData.apiAddrGraphql,
  });
  return (
    <ApolloClient.ApolloProvider client={apolloClient}>
      <AppApolloClient.ApolloClientContext.Provider value={apolloClient}>
        <InnerApp />
      </AppApolloClient.ApolloClientContext.Provider>
    </ApolloClient.ApolloProvider>
  );
};
