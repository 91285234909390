import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import * as Immer from "immer";
import { useSysforuForeignForm } from "../sysforu-form";
import * as AppData from "../app-data";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import { useAttachErrorHandler } from "../app-error-handler";
import styles from "./national-form-agreements.module.css";
import LinearProgress from "@mui/material/LinearProgress";

export const ForeignFormAgreements = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuForeignForm();
  const apolloClient = React.useContext(ApolloClientContext);
  const attachErrorHandler = useAttachErrorHandler();

  const onNext = ({ withoutInvitation = false } = {}) =>
    attachErrorHandler(async () => {
      let newForm = formState.value;
      if (withoutInvitation) {
        newForm = Immer.produce(formState.value, (draft) => {
          draft.invitationCode = AppData.defaultInvitationCode;
        });
        formState.set(newForm);
      }

      // 폼 검사하기
      if (!newForm.agreePrivacyPolicy) {
        alert("Agree to the Privacy Policy");
        return;
      }

      // 초대권 검사하기
      {
        const invCode = newForm.invitationCode;

        if (invCode !== AppData.defaultInvitationCode) {
          const result = await apolloClient.query({
            query: GraphqlTypes.TicketIsAvailableDocument,
            variables: { id: invCode },
          });
          const isAvailable = result.data.ticketIsAvailable;
          if (!isAvailable) {
            alert("The invitation code is already used or not existing.");
            return;
          }
        }
      }

      // 여기 검사를 조금 하기
      // 아니면 그냥 다음으로
      formState.set(newForm);
      nav(`/foreign-form-details?${formState.getQueryString(newForm)}`);
    });

  return (
    <div className={styles.cont}>
      <LinearProgress
        variant="determinate"
        value={20}
        style={{ maxWidth: "100px", backgroundColor: "#D8D8D8" }}
        color="black"
      />
      <Mui.Typography variant="h6">Privacy Policy</Mui.Typography>
      <div className={styles.commonBundle}>
        <Mui.Typography variant="subtitle1">Content</Mui.Typography>
        <span
          style={{
            maxHeight: "150px",
            overflowY: "scroll",
            whiteSpace: "pre-wrap",
            border: "1px solid grey",
            padding: "4px",
          }}
        >
          {AppData.privacyPolicyEn}
        </span>
        {/* <Mui.Typography
          variant="body1"
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
            whiteSpace: "pre-wrap",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        ></Mui.Typography> */}
      </div>
      <Mui.FormControlLabel
        control={
          <Mui.Checkbox
            checked={formState.value.agreePrivacyPolicy}
            onChange={(e) =>
              formState.set(
                Immer.produce(formState.value, (draft) => {
                  draft.agreePrivacyPolicy = e.target.checked;
                })
              )
            }
            style={{ height: "24px", alignSelf: "flex-start" }}
          />
        }
        label="I confirm that I have read and agree to the Privacy policy"
      />
      <div className={styles.invitationCode} style={{ marginTop: 20 }}>
        <Mui.Typography variant="h6">Invitation Number*</Mui.Typography>
        <Mui.TextField
          label="Invitation Number"
          value={formState.value.invitationCode}
          onChange={(e) =>
            formState.set(
              Immer.produce(formState.value, (draft) => {
                draft.invitationCode = e.target.value;
              })
            )
          }
          required
        />
        <Mui.Typography>⋇ If you don't have any invitations,</Mui.Typography>
        <Mui.Typography
          style={{ color: "blue", cursor: "pointer" }}
          onClick={onNext({ withoutInvitation: true })}
        >
          please click [HERE]
        </Mui.Typography>
      </div>
      <Mui.Button
        variant="contained"
        onClick={onNext()}
        style={{ marginTop: 24 }}
      >
        Next
      </Mui.Button>
    </div>
  );
};
