import React from "react";
import * as ApolloClient from "@apollo/client";

export const ApolloClientContext = React.createContext();

export const createApolloClient = ({ apiAddrGraphql }) =>
  new ApolloClient.ApolloClient({
    uri: apiAddrGraphql,
    cache: new ApolloClient.InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

export const useApolloClient = () => {
  return React.useContext(ApolloClientContext);
};
