import compressImage from "browser-image-compression";
import * as AppData from "../app-data";
import Axios from "axios";

export const useUploadImage = () => {
  return async (image) => {
    const fileCompressed = await compressImage(image, {
      maxSizeMB: 3,
      useWebWorker: true,
    });
    console.log(fileCompressed);

    const form = new FormData();
    form.append("file", fileCompressed);

    const result = await Axios({
      method: "post",
      url: `${AppData.apiAddr}/file-upload`,
      headers: { "Content-Type": "multipart/form-data" },
      data: form,
    });

    return result.data.newFileName;
  };
};
