import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Image: { input: any; output: any; }
  OcrResult: { input: any; output: any; }
};

export type Country = {
  __typename?: 'Country';
  codes: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FormForeign = {
  __typename?: 'FormForeign';
  agreeReceivingEmailNewsletters: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Country;
  department: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interestedProducts: Array<InterestedProduct>;
  invitationCode: Scalars['String']['output'];
  isInvited: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nameCardImage?: Maybe<Scalars['Image']['output']>;
  nameCardOcrResult?: Maybe<Scalars['OcrResult']['output']>;
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  purposeOfVisit: PurposeOfVisit;
  qrSource: Scalars['String']['output'];
  relatedIndustry: RelatedIndustry;
  tel: Scalars['String']['output'];
};

export type FormForeignInput = {
  agreeReceivingEmailNewsletters: Scalars['Boolean']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['ID']['input'];
  department: Scalars['String']['input'];
  email: Scalars['String']['input'];
  interestedProducts: Array<Scalars['ID']['input']>;
  invitationCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameCardImage?: InputMaybe<Scalars['Image']['input']>;
  nameCardOcrResult?: InputMaybe<Scalars['OcrResult']['input']>;
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
  purposeOfVisit: Scalars['ID']['input'];
  relatedIndustry: Scalars['ID']['input'];
  tel: Scalars['String']['input'];
};

export enum FormKind {
  Foreign = 'FOREIGN',
  National = 'NATIONAL'
}

export type FormNational = {
  __typename?: 'FormNational';
  agreeReceivingEmailNewsletters: Scalars['Boolean']['output'];
  agreeReceivingMarketingSms: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  companyAddress: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  department: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interestedProducts: Array<InterestedProduct>;
  invitationCode: Scalars['String']['output'];
  isInvited: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nameCardImage?: Maybe<Scalars['Image']['output']>;
  nameCardOcrResult?: Maybe<Scalars['OcrResult']['output']>;
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  qrSource: Scalars['String']['output'];
  tel: Scalars['String']['output'];
};

export type FormNationalInput = {
  agreeReceivingEmailNewsletters: Scalars['Boolean']['input'];
  agreeReceivingMarketingSms: Scalars['Boolean']['input'];
  companyAddress: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  department: Scalars['String']['input'];
  email: Scalars['String']['input'];
  interestedProducts: Array<Scalars['ID']['input']>;
  invitationCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameCardImage?: InputMaybe<Scalars['Image']['input']>;
  nameCardOcrResult?: InputMaybe<Scalars['OcrResult']['input']>;
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
  tel: Scalars['String']['input'];
};

export type InterestedProduct = {
  __typename?: 'InterestedProduct';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameMl: StringMl;
};

export type KeyFormForeign = {
  __typename?: 'KeyFormForeign';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type KeyFormForeignInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type KeyFormNational = {
  __typename?: 'KeyFormNational';
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type KeyFormNationalInput = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  formForeignCreate: FormForeign;
  formNationalCreate: FormNational;
};


export type MutationFormForeignCreateArgs = {
  form: FormForeignInput;
};


export type MutationFormNationalCreateArgs = {
  form: FormNationalInput;
};

export type PurposeOfVisit = {
  __typename?: 'PurposeOfVisit';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  countryReadAll: Array<Country>;
  formEmailExists?: Maybe<Scalars['Boolean']['output']>;
  formForeignRead: FormForeign;
  formForeignReadByKeyForm?: Maybe<FormForeign>;
  formNationalRead: FormNational;
  formNationalReadByKeyForm?: Maybe<FormNational>;
  interestedProductReadAll: Array<InterestedProduct>;
  purposeOfVisitReadAll: Array<PurposeOfVisit>;
  relatedIndustryReadAll: Array<RelatedIndustry>;
  ticketIsAvailable?: Maybe<Scalars['Boolean']['output']>;
};


export type QueryFormEmailExistsArgs = {
  email: Scalars['String']['input'];
};


export type QueryFormForeignReadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormForeignReadByKeyFormArgs = {
  keyForm: KeyFormForeignInput;
};


export type QueryFormNationalReadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormNationalReadByKeyFormArgs = {
  keyForm: KeyFormNationalInput;
};


export type QueryTicketIsAvailableArgs = {
  id: Scalars['ID']['input'];
};

export type RelatedIndustry = {
  __typename?: 'RelatedIndustry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StringMl = {
  __typename?: 'StringMl';
  en: Scalars['String']['output'];
  ko: Scalars['String']['output'];
};

export type FormForeignCreateMutationVariables = Exact<{
  form: FormForeignInput;
}>;


export type FormForeignCreateMutation = { __typename?: 'Mutation', formForeignCreate: { __typename?: 'FormForeign', id: string, email: string, name: string, invitationCode: string, isInvited: boolean, agreeReceivingEmailNewsletters: boolean, companyName: string, department: string, position: string, tel: string, phone: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, country: { __typename?: 'Country', id: string, name: string, codes: Array<string> }, relatedIndustry: { __typename?: 'RelatedIndustry', id: string, name: string }, purposeOfVisit: { __typename?: 'PurposeOfVisit', id: string, name: string }, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } };

export type FormNationalCreateMutationVariables = Exact<{
  form: FormNationalInput;
}>;


export type FormNationalCreateMutation = { __typename?: 'Mutation', formNationalCreate: { __typename?: 'FormNational', id: string, phone: string, name: string, agreeReceivingEmailNewsletters: boolean, agreeReceivingMarketingSms: boolean, invitationCode: string, isInvited: boolean, email: string, companyName: string, department: string, position: string, tel: string, companyAddress: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } };

export type CountryReadAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryReadAllQuery = { __typename?: 'Query', countryReadAll: Array<{ __typename?: 'Country', id: string, name: string, codes: Array<string> }> };

export type FormEmailExistsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type FormEmailExistsQuery = { __typename?: 'Query', formEmailExists?: boolean | null };

export type FormForeignReadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FormForeignReadQuery = { __typename?: 'Query', formForeignRead: { __typename?: 'FormForeign', id: string, email: string, name: string, invitationCode: string, isInvited: boolean, agreeReceivingEmailNewsletters: boolean, companyName: string, department: string, position: string, tel: string, phone: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, country: { __typename?: 'Country', id: string, name: string, codes: Array<string> }, relatedIndustry: { __typename?: 'RelatedIndustry', id: string, name: string }, purposeOfVisit: { __typename?: 'PurposeOfVisit', id: string, name: string }, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } };

export type FormForeignReadByKeyFormQueryVariables = Exact<{
  keyForm: KeyFormForeignInput;
}>;


export type FormForeignReadByKeyFormQuery = { __typename?: 'Query', formForeignReadByKeyForm?: { __typename?: 'FormForeign', id: string, email: string, name: string, invitationCode: string, isInvited: boolean, agreeReceivingEmailNewsletters: boolean, companyName: string, department: string, position: string, tel: string, phone: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, country: { __typename?: 'Country', id: string, name: string, codes: Array<string> }, relatedIndustry: { __typename?: 'RelatedIndustry', id: string, name: string }, purposeOfVisit: { __typename?: 'PurposeOfVisit', id: string, name: string }, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } | null };

export type FormNationalReadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FormNationalReadQuery = { __typename?: 'Query', formNationalRead: { __typename?: 'FormNational', id: string, phone: string, name: string, agreeReceivingEmailNewsletters: boolean, agreeReceivingMarketingSms: boolean, invitationCode: string, isInvited: boolean, email: string, companyName: string, department: string, position: string, tel: string, companyAddress: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } };

export type FormNationalReadByKeyFormQueryVariables = Exact<{
  keyForm: KeyFormNationalInput;
}>;


export type FormNationalReadByKeyFormQuery = { __typename?: 'Query', formNationalReadByKeyForm?: { __typename?: 'FormNational', id: string, phone: string, name: string, agreeReceivingEmailNewsletters: boolean, agreeReceivingMarketingSms: boolean, invitationCode: string, isInvited: boolean, email: string, companyName: string, department: string, position: string, tel: string, companyAddress: string, nameCardImage?: any | null, nameCardOcrResult?: any | null, code: string, qrSource: string, interestedProducts: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> } | null };

export type InterestedProductReadAllQueryVariables = Exact<{ [key: string]: never; }>;


export type InterestedProductReadAllQuery = { __typename?: 'Query', interestedProductReadAll: Array<{ __typename?: 'InterestedProduct', id: string, name: string, nameMl: { __typename?: 'StringMl', ko: string, en: string } }> };

export type PurposeOfVisitReadAllQueryVariables = Exact<{ [key: string]: never; }>;


export type PurposeOfVisitReadAllQuery = { __typename?: 'Query', purposeOfVisitReadAll: Array<{ __typename?: 'PurposeOfVisit', id: string, name: string }> };

export type RelatedIndustryReadAllQueryVariables = Exact<{ [key: string]: never; }>;


export type RelatedIndustryReadAllQuery = { __typename?: 'Query', relatedIndustryReadAll: Array<{ __typename?: 'RelatedIndustry', id: string, name: string }> };

export type TicketIsAvailableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TicketIsAvailableQuery = { __typename?: 'Query', ticketIsAvailable?: boolean | null };


export const FormForeignCreateDocument = gql`
    mutation formForeignCreate($form: FormForeignInput!) {
  formForeignCreate(form: $form) {
    id
    email
    name
    invitationCode
    isInvited
    agreeReceivingEmailNewsletters
    country {
      id
      name
      codes
    }
    companyName
    department
    position
    tel
    phone
    relatedIndustry {
      id
      name
    }
    purposeOfVisit {
      id
      name
    }
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormForeignCreateMutationFn = Apollo.MutationFunction<FormForeignCreateMutation, FormForeignCreateMutationVariables>;
export type FormForeignCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FormForeignCreateMutation, FormForeignCreateMutationVariables>, 'mutation'>;

    export const FormForeignCreateComponent = (props: FormForeignCreateComponentProps) => (
      <ApolloReactComponents.Mutation<FormForeignCreateMutation, FormForeignCreateMutationVariables> mutation={FormForeignCreateDocument} {...props} />
    );
    

/**
 * __useFormForeignCreateMutation__
 *
 * To run a mutation, you first call `useFormForeignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormForeignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formForeignCreateMutation, { data, loading, error }] = useFormForeignCreateMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useFormForeignCreateMutation(baseOptions?: Apollo.MutationHookOptions<FormForeignCreateMutation, FormForeignCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormForeignCreateMutation, FormForeignCreateMutationVariables>(FormForeignCreateDocument, options);
      }
export type FormForeignCreateMutationHookResult = ReturnType<typeof useFormForeignCreateMutation>;
export type FormForeignCreateMutationResult = Apollo.MutationResult<FormForeignCreateMutation>;
export type FormForeignCreateMutationOptions = Apollo.BaseMutationOptions<FormForeignCreateMutation, FormForeignCreateMutationVariables>;
export const FormNationalCreateDocument = gql`
    mutation formNationalCreate($form: FormNationalInput!) {
  formNationalCreate(form: $form) {
    id
    phone
    name
    agreeReceivingEmailNewsletters
    agreeReceivingMarketingSms
    invitationCode
    isInvited
    email
    companyName
    department
    position
    tel
    companyAddress
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormNationalCreateMutationFn = Apollo.MutationFunction<FormNationalCreateMutation, FormNationalCreateMutationVariables>;
export type FormNationalCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FormNationalCreateMutation, FormNationalCreateMutationVariables>, 'mutation'>;

    export const FormNationalCreateComponent = (props: FormNationalCreateComponentProps) => (
      <ApolloReactComponents.Mutation<FormNationalCreateMutation, FormNationalCreateMutationVariables> mutation={FormNationalCreateDocument} {...props} />
    );
    

/**
 * __useFormNationalCreateMutation__
 *
 * To run a mutation, you first call `useFormNationalCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormNationalCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formNationalCreateMutation, { data, loading, error }] = useFormNationalCreateMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useFormNationalCreateMutation(baseOptions?: Apollo.MutationHookOptions<FormNationalCreateMutation, FormNationalCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormNationalCreateMutation, FormNationalCreateMutationVariables>(FormNationalCreateDocument, options);
      }
export type FormNationalCreateMutationHookResult = ReturnType<typeof useFormNationalCreateMutation>;
export type FormNationalCreateMutationResult = Apollo.MutationResult<FormNationalCreateMutation>;
export type FormNationalCreateMutationOptions = Apollo.BaseMutationOptions<FormNationalCreateMutation, FormNationalCreateMutationVariables>;
export const CountryReadAllDocument = gql`
    query countryReadAll {
  countryReadAll {
    id
    name
    codes
  }
}
    `;
export type CountryReadAllComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountryReadAllQuery, CountryReadAllQueryVariables>, 'query'>;

    export const CountryReadAllComponent = (props: CountryReadAllComponentProps) => (
      <ApolloReactComponents.Query<CountryReadAllQuery, CountryReadAllQueryVariables> query={CountryReadAllDocument} {...props} />
    );
    

/**
 * __useCountryReadAllQuery__
 *
 * To run a query within a React component, call `useCountryReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryReadAllQuery(baseOptions?: Apollo.QueryHookOptions<CountryReadAllQuery, CountryReadAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryReadAllQuery, CountryReadAllQueryVariables>(CountryReadAllDocument, options);
      }
export function useCountryReadAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryReadAllQuery, CountryReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryReadAllQuery, CountryReadAllQueryVariables>(CountryReadAllDocument, options);
        }
export function useCountryReadAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountryReadAllQuery, CountryReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountryReadAllQuery, CountryReadAllQueryVariables>(CountryReadAllDocument, options);
        }
export type CountryReadAllQueryHookResult = ReturnType<typeof useCountryReadAllQuery>;
export type CountryReadAllLazyQueryHookResult = ReturnType<typeof useCountryReadAllLazyQuery>;
export type CountryReadAllSuspenseQueryHookResult = ReturnType<typeof useCountryReadAllSuspenseQuery>;
export type CountryReadAllQueryResult = Apollo.QueryResult<CountryReadAllQuery, CountryReadAllQueryVariables>;
export const FormEmailExistsDocument = gql`
    query formEmailExists($email: String!) {
  formEmailExists(email: $email)
}
    `;
export type FormEmailExistsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormEmailExistsQuery, FormEmailExistsQueryVariables>, 'query'> & ({ variables: FormEmailExistsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormEmailExistsComponent = (props: FormEmailExistsComponentProps) => (
      <ApolloReactComponents.Query<FormEmailExistsQuery, FormEmailExistsQueryVariables> query={FormEmailExistsDocument} {...props} />
    );
    

/**
 * __useFormEmailExistsQuery__
 *
 * To run a query within a React component, call `useFormEmailExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormEmailExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormEmailExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFormEmailExistsQuery(baseOptions: Apollo.QueryHookOptions<FormEmailExistsQuery, FormEmailExistsQueryVariables> & ({ variables: FormEmailExistsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormEmailExistsQuery, FormEmailExistsQueryVariables>(FormEmailExistsDocument, options);
      }
export function useFormEmailExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormEmailExistsQuery, FormEmailExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormEmailExistsQuery, FormEmailExistsQueryVariables>(FormEmailExistsDocument, options);
        }
export function useFormEmailExistsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormEmailExistsQuery, FormEmailExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormEmailExistsQuery, FormEmailExistsQueryVariables>(FormEmailExistsDocument, options);
        }
export type FormEmailExistsQueryHookResult = ReturnType<typeof useFormEmailExistsQuery>;
export type FormEmailExistsLazyQueryHookResult = ReturnType<typeof useFormEmailExistsLazyQuery>;
export type FormEmailExistsSuspenseQueryHookResult = ReturnType<typeof useFormEmailExistsSuspenseQuery>;
export type FormEmailExistsQueryResult = Apollo.QueryResult<FormEmailExistsQuery, FormEmailExistsQueryVariables>;
export const FormForeignReadDocument = gql`
    query formForeignRead($id: ID!) {
  formForeignRead(id: $id) {
    id
    email
    name
    invitationCode
    isInvited
    agreeReceivingEmailNewsletters
    country {
      id
      name
      codes
    }
    companyName
    department
    position
    tel
    phone
    relatedIndustry {
      id
      name
    }
    purposeOfVisit {
      id
      name
    }
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormForeignReadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormForeignReadQuery, FormForeignReadQueryVariables>, 'query'> & ({ variables: FormForeignReadQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormForeignReadComponent = (props: FormForeignReadComponentProps) => (
      <ApolloReactComponents.Query<FormForeignReadQuery, FormForeignReadQueryVariables> query={FormForeignReadDocument} {...props} />
    );
    

/**
 * __useFormForeignReadQuery__
 *
 * To run a query within a React component, call `useFormForeignReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormForeignReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormForeignReadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormForeignReadQuery(baseOptions: Apollo.QueryHookOptions<FormForeignReadQuery, FormForeignReadQueryVariables> & ({ variables: FormForeignReadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormForeignReadQuery, FormForeignReadQueryVariables>(FormForeignReadDocument, options);
      }
export function useFormForeignReadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormForeignReadQuery, FormForeignReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormForeignReadQuery, FormForeignReadQueryVariables>(FormForeignReadDocument, options);
        }
export function useFormForeignReadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormForeignReadQuery, FormForeignReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormForeignReadQuery, FormForeignReadQueryVariables>(FormForeignReadDocument, options);
        }
export type FormForeignReadQueryHookResult = ReturnType<typeof useFormForeignReadQuery>;
export type FormForeignReadLazyQueryHookResult = ReturnType<typeof useFormForeignReadLazyQuery>;
export type FormForeignReadSuspenseQueryHookResult = ReturnType<typeof useFormForeignReadSuspenseQuery>;
export type FormForeignReadQueryResult = Apollo.QueryResult<FormForeignReadQuery, FormForeignReadQueryVariables>;
export const FormForeignReadByKeyFormDocument = gql`
    query formForeignReadByKeyForm($keyForm: KeyFormForeignInput!) {
  formForeignReadByKeyForm(keyForm: $keyForm) {
    id
    email
    name
    invitationCode
    isInvited
    agreeReceivingEmailNewsletters
    country {
      id
      name
      codes
    }
    companyName
    department
    position
    tel
    phone
    relatedIndustry {
      id
      name
    }
    purposeOfVisit {
      id
      name
    }
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormForeignReadByKeyFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>, 'query'> & ({ variables: FormForeignReadByKeyFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormForeignReadByKeyFormComponent = (props: FormForeignReadByKeyFormComponentProps) => (
      <ApolloReactComponents.Query<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables> query={FormForeignReadByKeyFormDocument} {...props} />
    );
    

/**
 * __useFormForeignReadByKeyFormQuery__
 *
 * To run a query within a React component, call `useFormForeignReadByKeyFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormForeignReadByKeyFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormForeignReadByKeyFormQuery({
 *   variables: {
 *      keyForm: // value for 'keyForm'
 *   },
 * });
 */
export function useFormForeignReadByKeyFormQuery(baseOptions: Apollo.QueryHookOptions<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables> & ({ variables: FormForeignReadByKeyFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>(FormForeignReadByKeyFormDocument, options);
      }
export function useFormForeignReadByKeyFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>(FormForeignReadByKeyFormDocument, options);
        }
export function useFormForeignReadByKeyFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>(FormForeignReadByKeyFormDocument, options);
        }
export type FormForeignReadByKeyFormQueryHookResult = ReturnType<typeof useFormForeignReadByKeyFormQuery>;
export type FormForeignReadByKeyFormLazyQueryHookResult = ReturnType<typeof useFormForeignReadByKeyFormLazyQuery>;
export type FormForeignReadByKeyFormSuspenseQueryHookResult = ReturnType<typeof useFormForeignReadByKeyFormSuspenseQuery>;
export type FormForeignReadByKeyFormQueryResult = Apollo.QueryResult<FormForeignReadByKeyFormQuery, FormForeignReadByKeyFormQueryVariables>;
export const FormNationalReadDocument = gql`
    query formNationalRead($id: ID!) {
  formNationalRead(id: $id) {
    id
    phone
    name
    agreeReceivingEmailNewsletters
    agreeReceivingMarketingSms
    invitationCode
    isInvited
    email
    companyName
    department
    position
    tel
    companyAddress
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormNationalReadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormNationalReadQuery, FormNationalReadQueryVariables>, 'query'> & ({ variables: FormNationalReadQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormNationalReadComponent = (props: FormNationalReadComponentProps) => (
      <ApolloReactComponents.Query<FormNationalReadQuery, FormNationalReadQueryVariables> query={FormNationalReadDocument} {...props} />
    );
    

/**
 * __useFormNationalReadQuery__
 *
 * To run a query within a React component, call `useFormNationalReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormNationalReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormNationalReadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormNationalReadQuery(baseOptions: Apollo.QueryHookOptions<FormNationalReadQuery, FormNationalReadQueryVariables> & ({ variables: FormNationalReadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormNationalReadQuery, FormNationalReadQueryVariables>(FormNationalReadDocument, options);
      }
export function useFormNationalReadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormNationalReadQuery, FormNationalReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormNationalReadQuery, FormNationalReadQueryVariables>(FormNationalReadDocument, options);
        }
export function useFormNationalReadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormNationalReadQuery, FormNationalReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormNationalReadQuery, FormNationalReadQueryVariables>(FormNationalReadDocument, options);
        }
export type FormNationalReadQueryHookResult = ReturnType<typeof useFormNationalReadQuery>;
export type FormNationalReadLazyQueryHookResult = ReturnType<typeof useFormNationalReadLazyQuery>;
export type FormNationalReadSuspenseQueryHookResult = ReturnType<typeof useFormNationalReadSuspenseQuery>;
export type FormNationalReadQueryResult = Apollo.QueryResult<FormNationalReadQuery, FormNationalReadQueryVariables>;
export const FormNationalReadByKeyFormDocument = gql`
    query formNationalReadByKeyForm($keyForm: KeyFormNationalInput!) {
  formNationalReadByKeyForm(keyForm: $keyForm) {
    id
    phone
    name
    agreeReceivingEmailNewsletters
    agreeReceivingMarketingSms
    invitationCode
    isInvited
    email
    companyName
    department
    position
    tel
    companyAddress
    interestedProducts {
      id
      name
      nameMl {
        ko
        en
      }
    }
    nameCardImage
    nameCardOcrResult
    code
    qrSource
  }
}
    `;
export type FormNationalReadByKeyFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>, 'query'> & ({ variables: FormNationalReadByKeyFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormNationalReadByKeyFormComponent = (props: FormNationalReadByKeyFormComponentProps) => (
      <ApolloReactComponents.Query<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables> query={FormNationalReadByKeyFormDocument} {...props} />
    );
    

/**
 * __useFormNationalReadByKeyFormQuery__
 *
 * To run a query within a React component, call `useFormNationalReadByKeyFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormNationalReadByKeyFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormNationalReadByKeyFormQuery({
 *   variables: {
 *      keyForm: // value for 'keyForm'
 *   },
 * });
 */
export function useFormNationalReadByKeyFormQuery(baseOptions: Apollo.QueryHookOptions<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables> & ({ variables: FormNationalReadByKeyFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>(FormNationalReadByKeyFormDocument, options);
      }
export function useFormNationalReadByKeyFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>(FormNationalReadByKeyFormDocument, options);
        }
export function useFormNationalReadByKeyFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>(FormNationalReadByKeyFormDocument, options);
        }
export type FormNationalReadByKeyFormQueryHookResult = ReturnType<typeof useFormNationalReadByKeyFormQuery>;
export type FormNationalReadByKeyFormLazyQueryHookResult = ReturnType<typeof useFormNationalReadByKeyFormLazyQuery>;
export type FormNationalReadByKeyFormSuspenseQueryHookResult = ReturnType<typeof useFormNationalReadByKeyFormSuspenseQuery>;
export type FormNationalReadByKeyFormQueryResult = Apollo.QueryResult<FormNationalReadByKeyFormQuery, FormNationalReadByKeyFormQueryVariables>;
export const InterestedProductReadAllDocument = gql`
    query interestedProductReadAll {
  interestedProductReadAll {
    id
    name
    nameMl {
      ko
      en
    }
  }
}
    `;
export type InterestedProductReadAllComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>, 'query'>;

    export const InterestedProductReadAllComponent = (props: InterestedProductReadAllComponentProps) => (
      <ApolloReactComponents.Query<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables> query={InterestedProductReadAllDocument} {...props} />
    );
    

/**
 * __useInterestedProductReadAllQuery__
 *
 * To run a query within a React component, call `useInterestedProductReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestedProductReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestedProductReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterestedProductReadAllQuery(baseOptions?: Apollo.QueryHookOptions<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>(InterestedProductReadAllDocument, options);
      }
export function useInterestedProductReadAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>(InterestedProductReadAllDocument, options);
        }
export function useInterestedProductReadAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>(InterestedProductReadAllDocument, options);
        }
export type InterestedProductReadAllQueryHookResult = ReturnType<typeof useInterestedProductReadAllQuery>;
export type InterestedProductReadAllLazyQueryHookResult = ReturnType<typeof useInterestedProductReadAllLazyQuery>;
export type InterestedProductReadAllSuspenseQueryHookResult = ReturnType<typeof useInterestedProductReadAllSuspenseQuery>;
export type InterestedProductReadAllQueryResult = Apollo.QueryResult<InterestedProductReadAllQuery, InterestedProductReadAllQueryVariables>;
export const PurposeOfVisitReadAllDocument = gql`
    query purposeOfVisitReadAll {
  purposeOfVisitReadAll {
    id
    name
  }
}
    `;
export type PurposeOfVisitReadAllComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>, 'query'>;

    export const PurposeOfVisitReadAllComponent = (props: PurposeOfVisitReadAllComponentProps) => (
      <ApolloReactComponents.Query<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables> query={PurposeOfVisitReadAllDocument} {...props} />
    );
    

/**
 * __usePurposeOfVisitReadAllQuery__
 *
 * To run a query within a React component, call `usePurposeOfVisitReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurposeOfVisitReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurposeOfVisitReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurposeOfVisitReadAllQuery(baseOptions?: Apollo.QueryHookOptions<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>(PurposeOfVisitReadAllDocument, options);
      }
export function usePurposeOfVisitReadAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>(PurposeOfVisitReadAllDocument, options);
        }
export function usePurposeOfVisitReadAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>(PurposeOfVisitReadAllDocument, options);
        }
export type PurposeOfVisitReadAllQueryHookResult = ReturnType<typeof usePurposeOfVisitReadAllQuery>;
export type PurposeOfVisitReadAllLazyQueryHookResult = ReturnType<typeof usePurposeOfVisitReadAllLazyQuery>;
export type PurposeOfVisitReadAllSuspenseQueryHookResult = ReturnType<typeof usePurposeOfVisitReadAllSuspenseQuery>;
export type PurposeOfVisitReadAllQueryResult = Apollo.QueryResult<PurposeOfVisitReadAllQuery, PurposeOfVisitReadAllQueryVariables>;
export const RelatedIndustryReadAllDocument = gql`
    query relatedIndustryReadAll {
  relatedIndustryReadAll {
    id
    name
  }
}
    `;
export type RelatedIndustryReadAllComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>, 'query'>;

    export const RelatedIndustryReadAllComponent = (props: RelatedIndustryReadAllComponentProps) => (
      <ApolloReactComponents.Query<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables> query={RelatedIndustryReadAllDocument} {...props} />
    );
    

/**
 * __useRelatedIndustryReadAllQuery__
 *
 * To run a query within a React component, call `useRelatedIndustryReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedIndustryReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedIndustryReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelatedIndustryReadAllQuery(baseOptions?: Apollo.QueryHookOptions<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>(RelatedIndustryReadAllDocument, options);
      }
export function useRelatedIndustryReadAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>(RelatedIndustryReadAllDocument, options);
        }
export function useRelatedIndustryReadAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>(RelatedIndustryReadAllDocument, options);
        }
export type RelatedIndustryReadAllQueryHookResult = ReturnType<typeof useRelatedIndustryReadAllQuery>;
export type RelatedIndustryReadAllLazyQueryHookResult = ReturnType<typeof useRelatedIndustryReadAllLazyQuery>;
export type RelatedIndustryReadAllSuspenseQueryHookResult = ReturnType<typeof useRelatedIndustryReadAllSuspenseQuery>;
export type RelatedIndustryReadAllQueryResult = Apollo.QueryResult<RelatedIndustryReadAllQuery, RelatedIndustryReadAllQueryVariables>;
export const TicketIsAvailableDocument = gql`
    query ticketIsAvailable($id: ID!) {
  ticketIsAvailable(id: $id)
}
    `;
export type TicketIsAvailableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>, 'query'> & ({ variables: TicketIsAvailableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TicketIsAvailableComponent = (props: TicketIsAvailableComponentProps) => (
      <ApolloReactComponents.Query<TicketIsAvailableQuery, TicketIsAvailableQueryVariables> query={TicketIsAvailableDocument} {...props} />
    );
    

/**
 * __useTicketIsAvailableQuery__
 *
 * To run a query within a React component, call `useTicketIsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketIsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketIsAvailableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketIsAvailableQuery(baseOptions: Apollo.QueryHookOptions<TicketIsAvailableQuery, TicketIsAvailableQueryVariables> & ({ variables: TicketIsAvailableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>(TicketIsAvailableDocument, options);
      }
export function useTicketIsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>(TicketIsAvailableDocument, options);
        }
export function useTicketIsAvailableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>(TicketIsAvailableDocument, options);
        }
export type TicketIsAvailableQueryHookResult = ReturnType<typeof useTicketIsAvailableQuery>;
export type TicketIsAvailableLazyQueryHookResult = ReturnType<typeof useTicketIsAvailableLazyQuery>;
export type TicketIsAvailableSuspenseQueryHookResult = ReturnType<typeof useTicketIsAvailableSuspenseQuery>;
export type TicketIsAvailableQueryResult = Apollo.QueryResult<TicketIsAvailableQuery, TicketIsAvailableQueryVariables>;