import React, { useState } from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import * as Immer from "immer";
import * as R from "ramda";
import { useSysforuForeignForm } from "../sysforu-form";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import * as Util from "../utils";
import { useAttachErrorHandler } from "../app-error-handler";
import { CountriesContext } from "../countries";
import { InterestedProductsContext } from "../interested-products";
import { RelatedIndustriesContext } from "../related-industries";
import { PurposeOfVisitsContext } from "../purpose-of-visits";
import { useOcrRead } from "../ocr";
import styles from "./national-form-details.module.css";
import LinearProgress from "@mui/material/LinearProgress";
import compressImage from "browser-image-compression";
import { useUploadImage } from "../hook/upload-image";

export const ForeignFormDetails = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuForeignForm();
  const phoneToString = Util.usePhoneToString();
  const attachErrorHandler = useAttachErrorHandler();
  const apolloClient = React.useContext(ApolloClientContext);
  const [progress, _setProgress] = useState(60);
  const [phoneNationalCode, SetPhoneNationalCode] = useState("");
  const [companyNationalCode, SetCompanyNationalCode] = useState("");
  const ocrRead = useOcrRead();
  const uploadImage = useUploadImage();

  const onNext = attachErrorHandler(async () => {
    // 폼 검사하기
    if (formState.value.country == null) {
      alert("Enter country");
      return;
    }
    if (formState.value.companyName.length === 0) {
      alert("Enter company name");
      return;
    }
    if (formState.value.tel.length < 8) {
      alert("Enter company tel more than 7 characters");
      return;
    }
    if (formState.value.phone.length < 8) {
      alert("Enter mobile more than 7 characters");
      return;
    }
    if (phoneNationalCode.length === 0) {
      alert("Enter nationalCode");
      return;
    }
    if (companyNationalCode.length === 0) {
      alert("Enter nationalCode");
      return;
    }
    if (!phoneNationalCode.startsWith("+")) {
      alert('Mobile field needs country code(starts with "+")');
      return;
    }
    if (!companyNationalCode.startsWith("+")) {
      alert('Tel field needs country code(starts with "+")');
      return;
    }
    if (formState.value.interestedProducts.length === 0) {
      alert("Enter interested products.");
      return;
    }
    if (formState.value.relatedIndustry == null) {
      alert("Enter related industry");
      return;
    }
    if (formState.value.purposeOfVisit == null) {
      alert("Enter purpose of visit");
      return;
    }

    // 제출하기
    let formId;
    {
      formState.set({
        ...formState.value,
        phone: `${phoneNationalCode} ${formState.value.phone}`,
        tel: `${companyNationalCode} ${formState.value.tel}`,
      });
      const result = await apolloClient.mutate({
        mutation: GraphqlTypes.FormForeignCreateDocument,
        variables: {
          form: R.pipe(
            R.omit(["agreePrivacyPolicy"]),
            R.over(R.lensProp("phone"), phoneToString)
          )(formState.value),
        },
      });

      formId = result.data.formForeignCreate.id;
    }

    nav(`/foreign-form-result?key=${formId}`);
  });

  const doOcr = ({ takePhoto = false } = {}) =>
    attachErrorHandler(async () => {
      // Take image from the user.
      let file;
      {
        file = await new Promise((res) => {
          const input = document.createElement("input");
          input.style = "display: none";
          input.type = "file";
          input.accept = "image/jpeg, image/png";
          if (takePhoto) {
            input.capture = "";
          }
          // input.onchange = () => {
          //   if (input.files == null || input.files.length === 0) {
          //     return;
          //   }
          //   const fileUploaded = input.files[0];
          //   res(fileUploaded);
          // };
          input.addEventListener("change", () => {
            if (input.files == null || input.files.length === 0) {
              return;
            }
            const fileUploaded = input.files[0];
            res(fileUploaded);
          });
          document.getElementsByTagName("body")[0].appendChild(input);
          input.click();
        });
      }

      // 이미지 데이터 업로드
      const fileId = await uploadImage(file);

      // OCR 실행
      const result = await ocrRead(file);

      // 결과에서 데이터 추출하기: 대상은 누구?
      const lensFactory = (key) =>
        R.lensPath(["images", 0, "nameCard", "result", key, 0, "text"]);
      const viewByKey = (key) => R.view(lensFactory(key), result);
      const availableFields = {};
      const attach = (ocrName, targetName = ocrName) => {
        availableFields[targetName] =
          Util.run(
            () => viewByKey(ocrName),
            () => null
          ) ?? null;
      };
      attach("name");
      attach("company", "companyName");
      attach("department");
      attach("address", "companyAddress");
      attach("position");
      attach("mobile", "phone");
      attach("tel");
      attach("email");
      for (const key in availableFields) {
        if (availableFields[key] == null) {
          delete availableFields[key];
        }
      }

      // 하지만 제외되어야 하는 필드들(name 등 이전에 입력하였거나 폼에 존재하지 않아야 하는 필드들)을 제외하기
      const exceptions = ["name", "email", "companyAddress"];
      const assignable = R.omit(exceptions, availableFields);

      // 폼에 반영하기
      formState.set({
        ...formState.value,
        ...assignable,
        nameCardImage: fileId,
        nameCardOcrResult: result,
      });

      // 완료되었다고 알려주기
      alert("OCR filling was completed");
    });

  const countries = React.useContext(CountriesContext);
  const interestedProducts = React.useContext(InterestedProductsContext);
  const relatedIndustries = React.useContext(RelatedIndustriesContext);
  const purposeOfVisits = React.useContext(PurposeOfVisitsContext);

  return (
    <div className={styles.cont}>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{ maxWidth: "100px", backgroundColor: "#D8D8D8" }}
        color="black"
      />
      <Mui.Typography variant="h6">Foreigner Registration</Mui.Typography>

      <Mui.Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          marginBottom: "12px",
        }}
      >
        {/* <Mui.FormLabel>OCR Form Input</Mui.FormLabel> */}
        <Mui.Box style={{ display: "flex", gap: 12 }}>
          <Mui.Button
            variant="outlined"
            onClick={doOcr({ takePhoto: true })}
            style={{ flex: 1, color: "blue", borderColor: "blue" }}
          >
            Scan BusinessCard
          </Mui.Button>
          <Mui.Button
            variant="outlined"
            onClick={doOcr()}
            style={{ flex: 1, color: "blue", borderColor: "blue" }}
          >
            Attach image file
          </Mui.Button>
        </Mui.Box>
        <Mui.Typography style={{ color: "blue" }}>
          ⋇ Recognizes business cards by item with high accuracy.
        </Mui.Typography>
      </Mui.Box>

      <Mui.TextField label="Name*" value={formState.value.name} disabled />
      <Mui.TextField label="Email*" value={formState.value.email} disabled />
      <Mui.FormControlLabel
        control={
          <Mui.Checkbox
            checked={formState.value.agreeReceivingEmailNewsletters}
            onChange={(e) =>
              formState.set(
                Immer.produce(formState.value, (draft) => {
                  draft.agreeReceivingEmailNewsletters = e.target.checked;
                })
              )
            }
          />
        }
        label="I agree to receive SIMTOS email"
      />

      <Mui.FormControl>
        <Mui.InputLabel required>Country</Mui.InputLabel>
        <Mui.Select
          value={formState.value.country ?? ""}
          label="Country"
          onChange={(e) => {
            const countryId = e.target.value;
            const country = countries.find((a) => a.id === countryId);

            SetCompanyNationalCode(country.codes[0]);
            SetPhoneNationalCode(country.codes[0]);
            formState.set(
              Immer.produce(formState.value, (draft) => {
                draft.country = countryId;
                // draft.companyNationalCode = `${country.codes[0]}`;
                // draft.phoneNationalCode = `${country.codes[0]}`;
              })
            );
          }}
          required
        >
          {countries.map(({ name, id }) => {
            return (
              <Mui.MenuItem key={id} value={id}>
                {name}
              </Mui.MenuItem>
            );
          })}
        </Mui.Select>
      </Mui.FormControl>

      <Mui.TextField
        label="Company Name"
        value={formState.value.companyName}
        onChange={(e) =>
          formState.set({ ...formState.value, companyName: e.target.value })
        }
        required
      />
      <Mui.TextField
        label="Department"
        value={formState.value.department}
        onChange={(e) =>
          formState.set({
            ...formState.value,
            department: e.target.value,
          })
        }
      />
      <Mui.TextField
        label="Position"
        value={formState.value.position}
        onChange={(e) =>
          formState.set({
            ...formState.value,
            position: e.target.value,
          })
        }
      />
      <div style={{ display: "flex", gap: 4 }}>
        <Mui.TextField
          label="code"
          placeholder="+82"
          value={companyNationalCode}
          onChange={(e) => SetCompanyNationalCode(e.target.value)}
          style={{ flex: 0.3 }}
          required
        />

        <Mui.TextField
          label="Tel"
          value={formState.value.tel}
          onChange={(e) =>
            formState.set({
              ...formState.value,
              tel: e.target.value,
            })
          }
          style={{ flex: 0.7 }}
          required
        />
      </div>
      <div style={{ display: "flex", gap: 4 }}>
        <Mui.TextField
          label="code"
          placeholder="+82"
          value={phoneNationalCode}
          onChange={(e) => SetPhoneNationalCode(e.target.value)}
          style={{ flex: 0.3 }}
          required
        />

        <Mui.TextField
          label="Mobile"
          value={formState.value.phone}
          onChange={(e) =>
            formState.set({
              ...formState.value,
              phone: e.target.value,
            })
          }
          style={{ flex: 0.7 }}
          required
        />
      </div>

      <Mui.FormControl>
        <Mui.InputLabel>
          Related Industry <span style={{ color: "red" }}>*</span>
        </Mui.InputLabel>
        <Mui.Select
          value={formState.value.relatedIndustry ?? ""}
          label="Related Industry"
          onChange={(e) => {
            formState.set(
              Immer.produce(formState.value, (draft) => {
                draft.relatedIndustry = e.target.value;
              })
            );
          }}
        >
          {relatedIndustries.map(({ name, id }) => {
            return (
              <Mui.MenuItem key={id} value={id}>
                {name}
              </Mui.MenuItem>
            );
          })}
        </Mui.Select>
      </Mui.FormControl>

      <Mui.FormControl>
        <Mui.InputLabel>
          Purpose of Visit <span style={{ color: "red" }}>*</span>
        </Mui.InputLabel>
        <Mui.Select
          value={formState.value.purposeOfVisit ?? ""}
          label="Purpose of Visit"
          onChange={(e) => {
            formState.set(
              Immer.produce(formState.value, (draft) => {
                draft.purposeOfVisit = e.target.value;
              })
            );
          }}
        >
          {purposeOfVisits.map(({ name, id }) => {
            return (
              <Mui.MenuItem key={id} value={id}>
                {name}
              </Mui.MenuItem>
            );
          })}
        </Mui.Select>
      </Mui.FormControl>

      <Mui.FormControl>
        <Mui.FormLabel required>Interested Products</Mui.FormLabel>
        <Mui.FormGroup>
          {interestedProducts.map(({ id, nameMl }) => {
            return (
              <Mui.FormControlLabel
                key={id}
                control={
                  <Mui.Checkbox
                    checked={formState.value.interestedProducts.includes(id)}
                    onChange={(e) => {
                      const prevChecked =
                        formState.value.interestedProducts.includes(id);
                      const newChecked = e.target.checked;

                      if (prevChecked === newChecked) {
                        return;
                      }

                      if (newChecked) {
                        formState.set(
                          Immer.produce(formState.value, (draft) => {
                            draft.interestedProducts.push(id);
                          })
                        );
                        return;
                      }

                      formState.set(
                        Immer.produce(formState.value, (draft) => {
                          draft.interestedProducts =
                            draft.interestedProducts.filter((a) => a !== id);
                        })
                      );
                    }}
                  />
                }
                label={nameMl.en}
              />
            );
          })}
        </Mui.FormGroup>
      </Mui.FormControl>
      <Mui.Button variant="contained" onClick={onNext}>
        Submit
      </Mui.Button>
    </div>
  );
};
