import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import * as Immer from "immer";
import { useSysforuNationalForm } from "../sysforu-form";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import { useAttachErrorHandler } from "../app-error-handler";
import * as AppData from "../app-data";
import styles from "./national-form-agreements.module.css";
import LinearProgress from "@mui/material/LinearProgress";

export const NationalFormAgreements = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuNationalForm();
  const attachErrorHandler = useAttachErrorHandler();
  const apolloClient = React.useContext(ApolloClientContext);

  const onNext = ({ withoutInvitation = false } = {}) =>
    attachErrorHandler(async () => {
      let newForm = formState.value;
      if (withoutInvitation) {
        newForm = Immer.produce(formState.value, (draft) => {
          draft.invitationCode = AppData.defaultInvitationCode;
        });
        formState.set(newForm);
      }

      // 폼 검사하기
      if (!newForm.ge14) {
        alert("만 14세 이상만이 신청할 수 있습니다");
        return;
      }

      if (!newForm.agreePrivacyPolicy) {
        alert("개인정보 처리방침에 동의해주세요");
        return;
      }

      if (!newForm.invitationCode) {
        alert("초대권 번호를 입력하세요.");
        return;
      }

      // 초대권 검사하기
      {
        const invCode = newForm.invitationCode;

        if (invCode !== AppData.defaultInvitationCode) {
          const result = await apolloClient.query({
            query: GraphqlTypes.TicketIsAvailableDocument,
            variables: { id: invCode },
          });
          const isAvailable = result.data.ticketIsAvailable;
          if (!isAvailable) {
            alert("이미 사용되었거나 존재하지 않는 초대권입니다.");
            return;
          }
        }
      }

      // 여기 검사를 조금 하기
      // 아니면 그냥 다음으로
      nav(`/national-form-details?${formState.getQueryString(newForm)}`);
    });

  return (
    <div className={styles.cont}>
      <LinearProgress
        variant="determinate"
        value={20}
        style={{ maxWidth: "100px", backgroundColor: "#D8D8D8" }}
        color="black"
      />
      <Mui.Typography variant="h6">개인정보 수집 및 이용 동의</Mui.Typography>
      <div className={styles.commonBundle}>
        <Mui.FormControlLabel
          control={
            <Mui.Checkbox
              checked={formState.value.agreeAll}
              onChange={(e) => formState.setAgreeAll(e.target.checked)}
            />
          }
          label={
            <span style={{ fontSize: 16, fontWeight: 500 }}>전체 동의</span>
          }
          style={{ marginTop: -10, marginBottom: -10 }}
        />
        <Mui.Typography variant="caption" className={styles.agreeAllCaption}>
          전체 동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며,{"\n"}
          개별적으로도 동의를 선택하실 수 있습니다.
        </Mui.Typography>
      </div>
      <Mui.FormControlLabel
        style={{ marginTop: -10, marginBottom: -10 }}
        control={
          <Mui.Checkbox
            checked={formState.value.ge14}
            onChange={(e) =>
              formState.set(
                Immer.produce(formState.value, (draft) => {
                  draft.ge14 = e.target.checked;
                })
              )
            }
          />
        }
        label={
          <span style={{ fontSize: 14, fontWeight: 500 }}>
            만 14세 이상입니다.
          </span>
        }
      />
      <Mui.FormControlLabel
        style={{ marginTop: -10, marginBottom: -10 }}
        control={
          <Mui.Checkbox
            checked={formState.value.agreePrivacyPolicy}
            onChange={(e) =>
              formState.set(
                Immer.produce(formState.value, (draft) => {
                  draft.agreePrivacyPolicy = e.target.checked;
                })
              )
            }
          />
        }
        label={
          <span style={{ fontSize: 14, fontWeight: 500 }}>
            [필수] 개인정보 수집 및 이용 동의{" "}
            <a href={AppData.privacyPolicyKrUrl}>자세히</a>
          </span>
        }
      />
      <div className={styles.commonBundle}>
        <Mui.FormControlLabel
          style={{ marginTop: -10, marginBottom: -10 }}
          control={
            <Mui.Checkbox
              checked={formState.value.agreeReceivingEmailNewsletters}
              onChange={(e) =>
                formState.set(
                  Immer.produce(formState.value, (draft) => {
                    draft.agreeReceivingEmailNewsletters = e.target.checked;
                  })
                )
              }
            />
          }
          label={
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              [선택] SIMTOS 이메일(뉴스레터) 수신
            </span>
          }
        />
        <Mui.TableContainer>
          <Mui.Table>
            <Mui.TableHead
              className={styles.grayTableHeader}
              // style={{ fontSize: 13, fontWeight: 400 }}
            >
              <Mui.TableCell>수집목적</Mui.TableCell>
              <Mui.TableCell>수집항목</Mui.TableCell>
              <Mui.TableCell>보유기간</Mui.TableCell>
            </Mui.TableHead>
            <Mui.TableBody className={styles.grayTableBody}>
              <Mui.TableCell>SIMTOS 정기 뉴스레터 발송</Mui.TableCell>
              <Mui.TableCell>이메일</Mui.TableCell>
              <Mui.TableCell>5년</Mui.TableCell>
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </div>
      <div className={styles.commonBundle}>
        <Mui.FormControlLabel
          style={{ marginTop: -10, marginBottom: -10 }}
          control={
            <Mui.Checkbox
              checked={formState.value.agreeReceivingMarketingSms}
              onChange={(e) =>
                formState.set(
                  Immer.produce(formState.value, (draft) => {
                    draft.agreeReceivingMarketingSms = e.target.checked;
                  })
                )
              }
            />
          }
          label={
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              [선택] SIMTOS 마케팅(광고) 문자 수신
            </span>
          }
        />
        <Mui.TableContainer>
          <Mui.Table>
            <Mui.TableHead className={styles.grayTableHeader}>
              <Mui.TableCell>수집목적</Mui.TableCell>
              <Mui.TableCell>수집항목</Mui.TableCell>
              <Mui.TableCell>보유기간</Mui.TableCell>
            </Mui.TableHead>
            <Mui.TableBody className={styles.grayTableBody}>
              <Mui.TableCell>SIMTOS 마케팅(광고) 문자 발송</Mui.TableCell>
              <Mui.TableCell>휴대전화</Mui.TableCell>
              <Mui.TableCell>5년</Mui.TableCell>
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </div>
      <div className={styles.invitationCode}>
        <Mui.Typography
          variant="subtitle1"
          style={{
            padding: "4px 0px",
            borderTop: "1px solid #D8D8D8",
            borderBottom: "1px solid #D8D8D8",
          }}
        >
          ▶ 초대권 번호를 입력해주세요.
        </Mui.Typography>
        <Mui.TextField
          placeholder="초대권 번호를 입력해주세요"
          // label='초대권 번호를 입력해주세요'
          value={formState.value.invitationCode}
          onChange={(e) =>
            formState.set(
              Immer.produce(formState.value, (draft) => {
                draft.invitationCode = e.target.value;
              })
            )
          }
        />
        <Mui.Button
          variant="outlined"
          onClick={onNext({ withoutInvitation: true })}
          style={{
            border: "none",
            textDecoration: "underline",
            display: "block",
            textAlign: "left",
            padding: "8px 0px",
          }}
        >
          초대권이 없는 경우, 여기를 클릭하세요.
        </Mui.Button>
      </div>
      <Mui.Button
        variant="contained"
        onClick={onNext()}
        style={{ marginTop: 24 }}
      >
        다음
      </Mui.Button>
    </div>
  );
};
