import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Lodash from "lodash";
import * as RedirectToNationalFormKey from "./component/redirect-to-national-form-key";
import * as NationalFormKeyPage from "./page/national-form-key";
import * as NationalFormAgreementsPage from "./page/national-form-agreements";
import * as NationalFormDetailsPage from "./page/national-form-details";
import * as NationalFormResultPage from "./page/national-form-result";
import * as ForeignFormKeyPage from "./page/foreign-form-key";
import * as ForeignFormAgreementsPage from "./page/foreign-form-agreements";
import * as ForeignFormDetailsPage from "./page/foreign-form-details";
import * as ForeignFormResultPage from "./page/foreign-form-result";
import { PageContext } from "./page-context";
import { GlobalWrapper } from "./component/global-wrapper";

export const createRouter = () => {
  const sourceRouter = [
    {
      name: "index",
      lang: "NATIONAL",
      componentNoContext: RedirectToNationalFormKey.RedirectToNationalFormKey,
    },
    {
      name: "national form key",
      lang: "NATIONAL",
      componentNoContext: NationalFormKeyPage.NationalFormKey,
    },
    {
      name: "national form agreements",
      lang: "NATIONAL",
      componentNoContext: NationalFormAgreementsPage.NationalFormAgreements,
    },
    {
      name: "national form details",
      lang: "NATIONAL",
      componentNoContext: NationalFormDetailsPage.NationalFormDetails,
    },
    {
      name: "national form result",
      lang: "NATIONAL",
      componentNoContext: NationalFormResultPage.NationalFormResult,
    },
    {
      name: "foreign form key",
      lang: "FOREIGN",
      componentNoContext: ForeignFormKeyPage.ForeignFormKey,
    },
    {
      name: "foreign form agreements",
      lang: "FOREIGN",
      componentNoContext: ForeignFormAgreementsPage.ForeignFormAgreements,
    },
    {
      name: "foreign form details",
      lang: "FOREIGN",
      componentNoContext: ForeignFormDetailsPage.ForeignFormDetails,
    },
    {
      name: "foreign form result",
      lang: "FOREIGN",
      componentNoContext: ForeignFormResultPage.ForeignFormResult,
    },
  ];

  const fulfilled = sourceRouter.map(
    ({ name, componentNoContext, ...extra }) => {
      const ComponentNoContext = componentNoContext;
      const path = name === "index" ? "/" : `/${Lodash.kebabCase(name)}`;

      const component = (props) => {
        return (
          <PageContext.Provider value={result}>
            <GlobalWrapper>
              <ComponentNoContext {...props} />
            </GlobalWrapper>
          </PageContext.Provider>
        );
      };
      const Component = component;
      const element = <Component />;
      const result = {
        name,
        componentNoContext,
        path,
        component,
        element,
        ...extra,
      };
      return result;
    }
  );

  return ReactRouterDom.createBrowserRouter(fulfilled);
};
