import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import { useSysforuNationalForm } from "../sysforu-form";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import { useAttachErrorHandler } from "../app-error-handler";
import * as Util from "../utils";
import styles from "./national-form-key.module.css";
import * as R from "ramda";

export const NationalFormKey = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuNationalForm();
  const phoneToString = Util.usePhoneToString();
  const attachErrorHandler = useAttachErrorHandler();
  const apolloClient = React.useContext(ApolloClientContext);

  const onNext = attachErrorHandler(async () => {
    // 입력 검사
    if (formState.value.name.length === 0) {
      alert("성명을 입력해주세요");
      return;
    }
    if (formState.value.phone.length < 10) {
      alert("올바른 휴대폰 번호를 입력해주세요(10자 이상)");
      return;
    }

    // 키가 이미 등록되었는지 확인하고, 그렇다면 4 페이지로
    {
      const result = await apolloClient.query({
        query: GraphqlTypes.FormNationalReadByKeyFormDocument,
        variables: {
          keyForm: {
            name: formState.value.name,
            phone: phoneToString(formState.value.phone),
          },
        },
      });
      const exists = result.data.formNationalReadByKeyForm != null;
      if (exists) {
        alert(
          "이미 등록된 정보가 있습니다. 등록완료(확인) 페이지로 이동합니다."
        );
        nav(
          `/national-form-result?key=${result.data.formNationalReadByKeyForm.id}`
        );
        return;
      }
    }

    // 아니면 그냥 다음으로
    nav(`/national-form-agreements?${formState.getQueryString()}`);
  });

  const buttonColorChanger = () => {
    if (!formState.value.name || !formState.value.phone) {
      return "grey";
    }
    if (formState.value.name.length === 0) {
      return "grey";
    }
    if (formState.value.phone.length === 0) {
      return "grey";
    } else {
      return "primary";
    }
  };

  const telValidator = (e) => {
    // const value = e.target.value
    //   .replace(/[^0-9]/g, "")
    //   .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    //   .replace(/(-{1,2})$/g, "");
    const value = e.target.value;

    formState.set({ ...formState.value, phone: value });
  };

  const setFormStateField = (fieldName) => (newValue) => {
    formState.set({ ...formState.value, [fieldName]: newValue });
  };

  return (
    <div className={styles.cont}>
      <img style={{ width: "100%" }} src={require("../banner.png")} />
      <Mui.Typography
        variant="h6"
        style={{
          width: "100%",
          textAlign: "center",
          color: "white",
          backgroundColor: "#F0569A",
        }}
      >
        On-site Registration
      </Mui.Typography>
      <div className={styles.innerCont}>
        <Mui.TextField
          label="성명"
          placeholder="성명 입력"
          variant="outlined"
          value={formState.value.name}
          onChange={(e) =>
            formState.set({ ...formState.value, name: e.target.value })
          }
        />
        <Mui.TextField
          label="휴대폰"
          placeholder="휴대폰 번호 입력"
          variant="outlined"
          value={formState.value.phone}
          onChange={R.pipe(
            R.tap(Util.onlyNumber),
            Util.domValue,
            Util.no_korean,
            Util.insertHypen,
            setFormStateField("phone")
          )}
          inputProps={{
            maxLength: 13,
          }}
        />
        <Mui.Button
          variant="contained"
          onClick={onNext}
          color={buttonColorChanger()}
        >
          다음
        </Mui.Button>
      </div>
    </div>
  );
};
