import Axios from "axios";
import * as Uuid from "uuid";

export class UnsupportedOcrFileFormatError extends Error {
  constructor(mime) {
    super(`OCR에서 지원하지 않는 파일 형식입니다: ${mime}`);
    this.mime = mime;
  }
}

export const mimeToOcrFormat = (mime) => {
  if (mime === "image/jpeg") {
    return "jpg";
  }
  if (mime === "image/png") {
    return "png";
  }
  throw new UnsupportedOcrFileFormatError(mime);
};

const ocrRead = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append(
    "message",
    JSON.stringify({
      version: "V2",
      requestId: Uuid.v4(),
      timestamp: 0,
      images: [{ format: mimeToOcrFormat(file.type), name: "name-card" }],
    })
  );

  const result = await Axios({
    method: "post",
    url: `https://m7tjaccnds.apigw.ntruss.com/custom/v1/29479/2de9e8eaafa085a37995c48c6b0e10ae250ff078a03e7d5788fcc62a9f57fab5/document/name-card`,
    headers: {
      "X-OCR-SECRET": "YkxvcVdWdlhtS2dEV21vVWhkZHJBVVdNUXl2a091cmQ=",
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });

  return result.data;
};
export const useOcrRead = () => ocrRead;
