import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import * as GraphqlTypes from "../graphql/types";
import { QrContainer } from "../component/qr-container";

export const NationalFormResult = () => {
  const [searchParams] = ReactRouterDom.useSearchParams();
  const formId = searchParams.get("key");
  const formQuery = GraphqlTypes.useFormNationalReadQuery({
    variables: { id: formId },
  });

  if (formQuery.data == null) {
    return null;
  }

  const qrSource = formQuery.data.formNationalRead.id;
  const code = formQuery.data.formNationalRead.id;
  const name = formQuery.data.formNationalRead.name;
  const isInvited = formQuery.data.formNationalRead.isInvited;

  return (
    <Mui.Box>
      <img style={{ width: "100%" }} src={require("../banner.png")} />
      <div style={{ padding: 24 }}>
        <QrContainer
          qrSource={qrSource}
          code={code}
          name={name}
          isInvited={true}
        />
      </div>
    </Mui.Box>
  );
};
