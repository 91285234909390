import { handleError } from "./handle-error";

export const useAttachErrorHandler =
  () =>
  (fn) =>
  async (...args) => {
    try {
      const result = await fn(...args);
      return result;
    } catch (e) {
      await handleError(e);
    }
  };
