import React from "react";
import { TabWrapper } from "../tab-wrapper";
import styles from "./styles.module.css";

export const GlobalWrapper = ({ children }) => {
  return (
    <div className={styles.cont}>
      <TabWrapper>{children}</TabWrapper>
    </div>
  );
};
