import React from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Util from "../utils";
import * as Immer from "immer";
import * as R from "ramda";

const formId = "form";

const useFormState = (defaultForm) => {
  const [searchParams, setSearchParams] = ReactRouterDom.useSearchParams();

  const [store, setStore] = React.useState(
    () =>
      Util.run(
        () => Util.urlJsonParse(searchParams.get(formId)),
        () => null
      ) ?? defaultForm
  );

  const setStorePublic = (newValue) => {
    setStore(newValue);
    setSearchParams(
      { [formId]: Util.urlJsonSerialize(newValue) },
      { replace: true }
    );
  };

  const getQueryString = (customForm) => {
    return `${formId}=${Util.urlJsonSerialize(customForm ?? store)}`;
  };

  return { value: store, set: setStorePublic, getQueryString };
};

const defaultFormNational = {
  agreeReceivingEmailNewsletters: false,
  agreeReceivingMarketingSms: false,
  companyAddress: "",
  companyAddress2: "",
  companyAddressPostal: "",
  companyName: "",
  department: "",
  email: "",
  interestedProducts: [],
  invitationCode: "",
  name: "",
  nameCardImage: null,
  nameCardOcrResult: null,
  phone: "",
  position: "",
  tel: "",

  ge14: false,
  agreePrivacyPolicy: false,
};

const defaultFormForeign = {
  agreeReceivingEmailNewsletters: false,
  companyName: "",
  country: null,
  department: "",
  email: "",
  interestedProducts: [],
  invitationCode: "",
  name: "",
  nameCardImage: null,
  nameCardOcrResult: null,
  phone: "",
  position: "",
  purposeOfVisit: null,
  relatedIndustry: null,
  tel: "",

  agreePrivacyPolicy: false,
};

export const useSysforuNationalForm = () => {
  const formState = useFormState(defaultFormNational);

  const agreeAll =
    formState.value.ge14 &&
    formState.value.agreePrivacyPolicy &&
    formState.value.agreeReceivingEmailNewsletters &&
    formState.value.agreeReceivingMarketingSms;

  const setAgreeAll = (newValue) => {
    formState.set(
      Immer.produce(formState.value, (draft) => {
        draft.ge14 = newValue;
        draft.agreePrivacyPolicy = newValue;
        draft.agreeReceivingEmailNewsletters = newValue;
        draft.agreeReceivingMarketingSms = newValue;
      })
    );
  };

  const defaultSet = (newValue) => {
    formState.set(R.omit(["agreeAll"], newValue));
  };

  return {
    ...formState,
    value: { ...formState.value, agreeAll },
    set: defaultSet,
    setAgreeAll,
  };
};

export const useSysforuForeignForm = () => {
  const formState = useFormState(defaultFormForeign);
  return formState;
};
