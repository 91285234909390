import React, { useState } from "react";
import * as ReactRouterDom from "react-router-dom";
import * as Mui from "@mui/material";
import * as Immer from "immer";
import * as R from "ramda";
import { useSysforuNationalForm } from "../sysforu-form";
import { ApolloClientContext } from "../apollo-client";
import * as GraphqlTypes from "../graphql/types";
import * as Util from "../utils";
import { useAttachErrorHandler } from "../app-error-handler";
import { InterestedProductsContext } from "../interested-products";
import { useOcrRead } from "../ocr";
import styles from "./national-form-details.module.css";
import LinearProgress from "@mui/material/LinearProgress";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useUploadImage } from "../hook/upload-image";

export const NationalFormDetails = () => {
  const nav = ReactRouterDom.useNavigate();
  const formState = useSysforuNationalForm();
  const phoneToString = Util.usePhoneToString();
  const attachErrorHandler = useAttachErrorHandler();
  const apolloClient = React.useContext(ApolloClientContext);
  const ocrRead = useOcrRead();
  const interestedProducts = React.useContext(InterestedProductsContext);
  const [progress, _setProgress] = useState(60);
  const [isOpen, setIsOpen] = useState(false);
  const uploadImage = useUploadImage();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const telValidator = (e) => {
    // const value = e.target.value
    //   .replace(/[^0-9]/g, "")
    //   .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    //   .replace(/(-{1,2})$/g, "");
    const value = Util.insertHypen(e.target.value);

    formState.set({ ...formState.value, tel: value });
  };

  const checkEmailDuplication = attachErrorHandler(async () => {
    const email = formState.value.email;
    if (Util.catchError(() => Util.email_chk(email)).error) {
      alert("이메일을 올바르게 입력해주세요");
      return;
    }

    if (!validateEmail(email)) {
      alert("올바른 이메일을 입력해주세요.");
      return;
    }

    const result = await apolloClient.query({
      query: GraphqlTypes.FormEmailExistsDocument,
      variables: { email },
    });

    if (result.data.formEmailExists) {
      alert("이미 사용중인 이메일입니다");
      return;
    }

    alert("사용하실 수 있는 이메일입니다");
  });

  const onNext = attachErrorHandler(async () => {
    // 폼 검사하기
    if (Util.catchError(() => Util.email_chk(formState.value.email)).error) {
      alert("이메일을 올바르게 입력해주세요");
      return;
    }
    if (formState.value.companyName.length === 0) {
      alert("회사명을 입력해주세요");
      return;
    }
    if (formState.value.tel.length === 0) {
      alert("회사전화를 입력해주세요");
      return;
    }
    if (formState.value.interestedProducts.length === 0) {
      alert("관심상품을 입력해주세요.");
      return;
    }

    // 제출하기
    let formId;
    {
      const result = await apolloClient.mutate({
        mutation: GraphqlTypes.FormNationalCreateDocument,
        variables: {
          form: R.pipe(
            R.omit(["ge14", "agreePrivacyPolicy", "agreeAll"]),
            R.over(R.lensProp("phone"), phoneToString),
            ({
              companyAddress,
              companyAddress2,
              companyAddressPostal,
              ...extra
            }) => ({
              ...extra,
              companyAddress: `${companyAddress}+++++${companyAddress2}+++++${companyAddressPostal}`,
            })
          )(formState.value),
        },
      });

      formId = result.data.formNationalCreate.id;
    }

    nav(`/national-form-result?key=${formId}`);
  });

  const doOcr = ({ takePhoto = false } = {}) =>
    attachErrorHandler(async () => {
      // Take image from the user.
      let file;
      {
        file = await new Promise((res) => {
          const input = document.createElement("input");
          input.style = "display: none";
          input.type = "file";
          input.accept = "image/jpeg, image/png";
          if (takePhoto) {
            input.capture = "";
          }
          // input.onchange = () => {
          //   if (input.files == null || input.files.length === 0) {
          //     return;
          //   }
          //   const fileUploaded = input.files[0];
          //   res(fileUploaded);
          // };
          input.addEventListener("change", () => {
            if (input.files == null || input.files.length === 0) {
              return;
            }
            const fileUploaded = input.files[0];
            res(fileUploaded);
          });
          document.getElementsByTagName("body")[0].appendChild(input);
          input.click();
        });
      }

      const fileId = await uploadImage(file);

      const result = await ocrRead(file);

      // 결과에서 데이터 추출하기: 대상은 누구?
      const lensFactory = (key) =>
        R.lensPath(["images", 0, "nameCard", "result", key, 0, "text"]);
      const viewByKey = (key) => R.view(lensFactory(key), result);
      const availableFields = {};
      const attach = (ocrName, targetName = ocrName) => {
        availableFields[targetName] =
          Util.run(
            () => viewByKey(ocrName),
            () => null
          ) ?? null;
      };
      attach("name");
      attach("company", "companyName");
      attach("department");
      attach("address", "companyAddress");
      attach("position");
      attach("mobile", "phone");
      attach("tel");
      attach("email");
      for (const key in availableFields) {
        if (availableFields[key] == null) {
          delete availableFields[key];
        }
      }

      // 하지만 제외되어야 하는 필드들(name 등 이전에 입력하였거나 폼에 존재하지 않아야 하는 필드들)을 제외하기
      const exceptions = ["name", "phone"];
      const assignable = R.omit(exceptions, availableFields);

      // 폼에 반영하기
      formState.set({
        ...formState.value,
        ...assignable,
        nameCardImage: fileId,
        nameCardOcrResult: result,
      });

      // 완료되었다고 알려주기
      alert("OCR 내용 작성이 완료되었습니다");
    });

  const handleComplete = (data) => {
    let fullAddress = data.address;
    const { zonecode } = data;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    formState.set({
      ...formState.value,
      companyAddress: fullAddress,
      companyAddressPostal: zonecode,
    });
    setIsOpen(false);
  };

  const setFormStateField = (fieldName) => (newValue) => {
    formState.set({ ...formState.value, [fieldName]: newValue });
  };

  return (
    <>
      <Mui.Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </div>
      </Mui.Modal>
      <div className={styles.cont}>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ maxWidth: "100px", backgroundColor: "#D8D8D8" }}
          color="black"
        />
        <Mui.Typography variant="h6">기본 정보 입력</Mui.Typography>
        <Mui.Box style={{ display: "flex", gap: 12 }}>
          <Mui.Button
            variant="outlined"
            onClick={doOcr({ takePhoto: true })}
            style={{
              flex: 1,
              fontSize: "14px",
              fontWeight: 400,
              color: "blue",
              borderColor: "blue",
            }}
          >
            명함 스캔하기
          </Mui.Button>
          <Mui.Button
            variant="outlined"
            onClick={doOcr()}
            style={{
              flex: 1,
              fontSize: "14px",
              fontWeight: 400,
              color: "blue",
              borderColor: "blue",
            }}
          >
            명함 파일 첨부
          </Mui.Button>
        </Mui.Box>
        <Mui.Typography
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#4169E1",
            marginBottom: "12px",
          }}
        >
          ⋇ 명함 이미지내 텍스트를 인식하여 명함 정보를 추출합니다.
        </Mui.Typography>
        <Mui.TextField
          label="성명 *"
          value={formState.value.name}
          disabled
          size="small"
        />
        <Mui.TextField
          size="small"
          label="휴대전화번호 *"
          value={formState.value.phone}
          disabled
        />
        <Mui.Box style={{ display: "flex", gap: 12 }}>
          <Mui.TextField
            style={{ flex: 1 }}
            label={"이메일"}
            value={formState.value.email}
            onChange={(e) =>
              formState.set({ ...formState.value, email: e.target.value })
            }
            size="small"
            required
            type="email"
          />
          <Mui.Button variant="outlined" onClick={checkEmailDuplication}>
            중복확인
          </Mui.Button>
        </Mui.Box>

        <Mui.TextField
          label="회사명"
          value={formState.value.companyName}
          onChange={(e) =>
            formState.set({
              ...formState.value,
              companyName: e.target.value,
            })
          }
          size="small"
          required
        />
        <Mui.TextField
          label="부서"
          value={formState.value.department}
          onChange={(e) =>
            formState.set({
              ...formState.value,
              department: e.target.value,
            })
          }
          size="small"
        />
        <Mui.TextField
          label="직책"
          value={formState.value.position}
          onChange={(e) =>
            formState.set({ ...formState.value, position: e.target.value })
          }
          size="small"
        />
        <Mui.TextField
          label="회사전화"
          value={formState.value.tel}
          onChange={R.pipe(
            R.tap(Util.onlyNumber),
            Util.domValue,
            Util.no_korean,
            Util.insertHypenToTelephone,
            setFormStateField("tel")
          )}
          inputProps={{
            maxLength: 12,
          }}
          required
          size="small"
        />

        <Mui.Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Mui.FormLabel
            style={{
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            회사 주소
          </Mui.FormLabel>
          <Mui.TextField
            size="small"
            label="회사주소"
            value={formState.value.companyAddress}
            // onChange={(e) =>
            //   formState.set({
            //     ...formState.value,
            //     companyAddress: e.target.value,
            //   })
            // }
            onClick={() => setIsOpen(true)}
          />
          <Mui.TextField
            size="small"
            label="회사주소(상세)"
            value={formState.value.companyAddress2}
            onChange={(e) =>
              formState.set({
                ...formState.value,
                companyAddress2: e.target.value,
              })
            }
          />
          <Mui.TextField
            size="small"
            label="회사주소(우편번호)"
            value={formState.value.companyAddressPostal}
            onChange={(e) =>
              formState.set({
                ...formState.value,
                companyAddressPostal: e.target.value,
              })
            }
          />

          <Mui.Typography
            variant="caption"
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "#4169E1",
              padding: "8px 0px",
            }}
          >
            전시회 안내 인쇄물 발송에 사용됩니다.
          </Mui.Typography>
        </Mui.Box>

        <Mui.FormControl>
          <Mui.FormLabel style={{ fontSize: 14, fontWeight: 500 }}>
            ※ 관심 상품*
          </Mui.FormLabel>
          <Mui.FormGroup>
            {interestedProducts.map(({ id, name }) => {
              return (
                <Mui.FormControlLabel
                  key={id}
                  control={
                    <Mui.Checkbox
                      style={{ width: "36px", height: "36px" }}
                      checked={formState.value.interestedProducts.includes(id)}
                      onChange={(e) => {
                        const prevChecked =
                          formState.value.interestedProducts.includes(id);
                        const newChecked = e.target.checked;

                        if (prevChecked === newChecked) {
                          return;
                        }

                        if (newChecked) {
                          formState.set(
                            Immer.produce(formState.value, (draft) => {
                              draft.interestedProducts.push(id);
                            })
                          );
                          return;
                        }

                        formState.set(
                          Immer.produce(formState.value, (draft) => {
                            draft.interestedProducts =
                              draft.interestedProducts.filter((a) => a !== id);
                          })
                        );
                      }}
                    />
                  }
                  label={name}
                />
              );
            })}
          </Mui.FormGroup>
        </Mui.FormControl>
        <Mui.Button
          sx={{
            background: "linear-gradient(#f899ba, #EF4D94)",
          }}
          variant="contained"
          onClick={onNext}
        >
          등록완료
        </Mui.Button>
      </div>
    </>
  );
};
